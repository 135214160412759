import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

const RoutePlannerDestinationIcon = React.memo((props: GlyphProps) => (
  <Glyph width={32} height={32} {...props}>
    <g filter="url(#filter0_drpdi)">
      <circle r="10" transform="matrix(1 0 0 -1 16 14)" fill="white" />
      <circle r="11" transform="matrix(1 0 0 -1 16 14)" stroke="#BAC0C7" strokeWidth="2" />
    </g>
    <path
      d="M15.1667 14C15.1667 13.5398 15.5398 13.1667 16.0001 13.1667C16.4603 13.1667 16.8334 13.5398 16.8334 14V19.8334C16.8334 20.2936 16.4603 20.6667 16.0001 20.6667C15.5398 20.6667 15.1667 20.2936 15.1667 19.8334V14Z"
      fill="#BAC0C7"
    />
    <circle cx="16.0001" cy="10.6666" r="3.33333" fill="url(#paint0_linearrdpdi)" />
    <circle cx="16.0001" cy="10.6666" r="2.83333" stroke="black" strokeOpacity="0.12" />
    <defs>
      <filter
        id="filter0_drpdi"
        x="0"
        y="0"
        width="32"
        height="32"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0" />
        <feBlend mode="multiply" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <linearGradient
        id="paint0_linearrdpdi"
        x1="16.0001"
        y1="7.33331"
        x2="16.0001"
        y2="14"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#E75C3E" />
        <stop offset="1" stopColor="#D12702" />
      </linearGradient>
    </defs>
  </Glyph>
));

RoutePlannerDestinationIcon.displayName = 'RoutePlanner/RoutePlannerDestinationIcon';

export { RoutePlannerDestinationIcon };
