import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

export const DestinationArrivedIcon = React.memo((props: GlyphProps) => (
  <Glyph width={26} height={26} {...props}>
    <g clipPath="url(#clip0_2869_19041)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 23.5806C13 23.5806 22.1212 17.3782 22.1212 11.5406C22.1212 6.4328 17.836 2.41949 13 2.41949C8.16402 2.41949 3.87885 6.4328 3.87885 11.5406C3.87885 17.3782 13 23.5806 13 23.5806ZM16.2836 11.5407C16.2836 13.3541 14.8135 14.8243 13 14.8243C11.1865 14.8243 9.71639 13.3541 9.71639 11.5407C9.71639 9.72717 11.1865 8.25704 13 8.25704C14.8135 8.25704 16.2836 9.72717 16.2836 11.5407Z"
        fill="currentColor"
      />
    </g>
    <circle cx="13.3671" cy="12.3671" r="6.36707" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.867 20.6257C17.152 20.6257 20.6257 17.152 20.6257 12.867C20.6257 8.58202 17.152 5.10834 12.867 5.10834C8.58203 5.10834 5.10835 8.58202 5.10835 12.867C5.10835 17.152 8.58203 20.6257 12.867 20.6257ZM16.4725 11.2762C16.8182 10.8153 16.7248 10.1614 16.2639 9.81571C15.803 9.47003 15.1491 9.56344 14.8034 10.0243L12.2 13.4956L10.8337 12.1294C10.4263 11.722 9.76582 11.722 9.35843 12.1294C8.95105 12.5368 8.95105 13.1973 9.35843 13.6047L11.5752 15.8214C11.789 16.0353 12.0851 16.1458 12.3868 16.1243C12.6884 16.1029 12.9659 15.9516 13.1474 15.7097L16.4725 11.2762Z"
      fill="currentColor"
    />
    <defs>
      <clipPath id="clip0_2869_19041">
        <rect width="25.3806" height="25.3806" fill="white" />
      </clipPath>
    </defs>
  </Glyph>
));

DestinationArrivedIcon.displayName = 'DestinationArrivedIcon';
