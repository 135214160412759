import { CloseCircleButton } from 'components/common/Button';
import { Card } from 'components/common/Card';
import { CardProps } from 'components/common/Card/Card';
import { MetaText, SmallText } from 'components/common/Typography';
import { PanelFlex } from 'components/layout/Panel/PanelFlex';
import { makeStyles } from 'lib/makeStyles';
import React, { ReactNode } from 'react';

type TrackingTooltipCardProps = CardProps &
  ParentClassNameProp &
  ChildrenProp & { showClose?: boolean; relative?: boolean; onClose?: GenericHandler };

type TrackingTooltipCardHeaderProps = {
  title: string | ReactNode;
  subtitle?: string | ReactNode;
  icon?: ReactNode;
} & ParentClassNameProp &
  ChildrenProp;

const useStyles = makeStyles<ParentClassNameProp>()({
  root: (props) => ['z-map', props.className],
  header: (props) => [props.className],
  closeButton: ['absolute top-2 right-2']
});

export const TrackingTooltipCardHeader: React.FC<TrackingTooltipCardHeaderProps> = React.memo(
  ({ className, children, icon, title, subtitle }: TrackingTooltipCardHeaderProps) => {
    const styles = useStyles({ className });

    return (
      <PanelFlex
        align="start"
        justify="start"
        spacing="XS"
        className={styles.header}
        paddingY={false}
        paddingX="XS">
        {icon && icon}
        <PanelFlex column align="start" spacing={false} padding={false}>
          {typeof title === 'string' ? <MetaText>{title}</MetaText> : title}
          {typeof subtitle === 'string' ? <SmallText thin>{subtitle}</SmallText> : subtitle}
        </PanelFlex>
        {children}
      </PanelFlex>
    );
  }
);

TrackingTooltipCardHeader.displayName = 'TrackingTooltipCardHeader';

export const TrackingTooltipCard: React.FC<TrackingTooltipCardProps> = React.memo(
  ({
    className,
    children,
    showClose = false,
    relative = true,
    onClose,
    ...cardProps
  }: TrackingTooltipCardProps) => {
    const styles = useStyles({ className });

    return (
      <Card
        className={styles.root}
        shadow="MD"
        relative={relative}
        paddingY="MD"
        paddingX="XS"
        rounded="LG"
        {...cardProps}>
        {children}
        {showClose && <CloseCircleButton className={styles.closeButton} onClose={onClose} />}
      </Card>
    );
  }
);

TrackingTooltipCard.displayName = 'TrackingTooltipCard';
