import { LngLatBounds } from 'mapbox-gl';

export type MapBoundingBox = [
  minLatitude: number,
  maxLatitude: number,
  minLongitude: number,
  maxLongitude: number
];

export const formatMapBounds = (bounds: LngLatBounds): MapBoundingBox => {
  const { lng: minLongitude, lat: minLatitude } = bounds.getSouthWest();
  const { lng: maxLongitude, lat: maxLatitude } = bounds.getNorthEast();

  return [minLatitude, maxLatitude, minLongitude, maxLongitude];
};
