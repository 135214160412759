import {
  buildLatLon,
  fetchAllPlaceMetatypes,
  fetchAllPlaceTypes,
  fetchLookupPlace,
  fetchNearbyPointsOfInterest,
  fetchPlace,
  fetchPlaceReviews,
  fetchPlaceReviewTemplate,
  fetchPlaceTypeConfig,
  getPlacePrimarySlug,
  hasReviewsEnabled,
  IPlace,
  isPlaceRecord,
  parsePlaceMetaTypeSections
} from '@truckmap/common';
import { loadContentfulPlaceDetailsData } from 'lib/contentful/loadContentfulData';
import { normalizeLocale } from 'lib/normalizeLocale';
import { filterPageSections } from 'lib/place/filterPageSections';
import { parsePlaceSummary } from 'lib/place/parsePlaceSummary';
import { GetStaticProps } from 'next';
import { checkForExtraPlaceDetailData } from 'pages/api/extraPlaceDetailData';
import { fetchaAndParsePlaceAnnotationsData } from 'pages/api/placeAnnotationsData';
import { truckMapConfig } from 'truckMapConfig';
import { PlaceRoutes, PlaceRoutesContext } from 'types/common';

const redirectToSlug = (slug: string, tab?: string) => ({
  redirect: {
    permanent: true,
    destination: `/place/${slug}${tab ? `/${tab}` : ''}`
  }
});
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const callIfValidPlace = async <R = any>(
  place: IPlace,
  promiseToCall: () => Promise<R>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fallback: any = [],
  validator = isPlaceRecord
) => (validator(place) ? promiseToCall() : Promise.resolve(fallback));

export const lookupPlace = async (slugOrUUID: string): Promise<IPlace> => {
  const placeLookup = await fetchLookupPlace(slugOrUUID);
  return isPlaceRecord(placeLookup) ? await fetchPlace(placeLookup.id) : placeLookup;
};

export const placeDetailsMiddleware: { [key in PlaceRoutes]: GetStaticProps } = {
  async [PlaceRoutes.UUID_REDIRECT](ctx: PlaceRoutesContext) {
    const place = await fetchPlace(ctx.params.relativePath[0]);
    const placeSlug = getPlacePrimarySlug(place);

    if (placeSlug) {
      // eslint-disable-next-line prefer-spread
      return redirectToSlug(placeSlug, ctx.params.relativePath[1]);
    }

    // if uuid doesn't have slug, do not redirect and just render page
    return placeDetailsMiddleware[PlaceRoutes.DETAILS](ctx);
  },
  async [PlaceRoutes.DETAILS]({
    params: {
      locale = truckMapConfig.i18n.defaultLocale,
      relativePath: [slug]
    }
  }: PlaceRoutesContext) {
    const place = await lookupPlace(slug);
    const placeSlug = getPlacePrimarySlug(place);

    // if slug is a generic lookup word or a non-primary slug, redirects to proper slug
    // example /walmart redirects to /walmart-supercenter-2884-8060-w-tropical-pkwy-las-vegas-nv-89149
    // /san-francisco redirects to /san-francisco-ca-usa
    if (placeSlug && placeSlug !== slug) {
      return redirectToSlug(placeSlug);
    }

    const { latitude, longitude } = buildLatLon(place);

    const [
      { contentfulQuery },
      placeTypeConfig,
      allPlaceMetaTypes,
      allPlaceTypes,
      placeNearbyPoints,
      review,
      reviewTemplate,
      extraPlaceDetailsData
    ] = await Promise.all([
      loadContentfulPlaceDetailsData(normalizeLocale(locale)),
      fetchPlaceTypeConfig(place.placeType.slug),
      fetchAllPlaceMetatypes(),
      fetchAllPlaceTypes(),
      fetchNearbyPointsOfInterest({
        lon: longitude,
        lat: latitude,
        limit: truckMapConfig.places.nearbyLimit,
        placeId: place.id
      }),
      callIfValidPlace(
        place,
        () =>
          fetchPlaceReviews({
            placeId: place.id,
            limit: 3
          }),
        [],
        hasReviewsEnabled
      ),
      callIfValidPlace(
        place,
        () => fetchPlaceReviewTemplate(place.placeTypeId),
        [],
        hasReviewsEnabled
      ),
      callIfValidPlace(place, () => checkForExtraPlaceDetailData(place), {})
    ]);

    const placeMetatypeSections = filterPageSections(
      parsePlaceMetaTypeSections(place, placeTypeConfig, allPlaceMetaTypes)
    );

    const placeSummary =
      (isPlaceRecord(place) && parsePlaceSummary(place, contentfulQuery)) ?? null;

    return {
      props: {
        place,
        allPlaceTypes,
        allPlaceMetaTypes,
        contentfulQuery,
        placeTypeConfig,
        placeMetatypeSections,
        placeNearbyPoints,
        placeSummary,
        review,
        reviewTemplate,
        extraPlaceDetailsData
      }
    };
  },
  async [PlaceRoutes.REVIEWS]({
    params: {
      locale = truckMapConfig.i18n.defaultLocale,
      relativePath: [slug, tab, page]
    }
  }: PlaceRoutesContext) {
    const place = await lookupPlace(slug);
    const placeSlug = getPlacePrimarySlug(place);

    if (!hasReviewsEnabled(place)) {
      return redirectToSlug(placeSlug ?? place.id ?? slug);
    }

    if ((placeSlug && placeSlug !== slug) || page === '1') {
      return redirectToSlug(placeSlug, tab);
    }

    const [{ contentfulQuery }, review, reviewTemplate] = await Promise.all([
      loadContentfulPlaceDetailsData(normalizeLocale(locale)),
      fetchPlaceReviews({
        placeId: place.id,
        limit: truckMapConfig.placeDetails.reviewsPerPage,
        page: page ? parseInt(page, 10) : 1
      }),
      fetchPlaceReviewTemplate(place.placeTypeId)
    ]);

    return {
      props: {
        place,
        contentfulQuery,
        review,
        reviewTemplate
      }
    };
  },
  async [PlaceRoutes.LOCAL_MAP]({
    params: {
      locale = truckMapConfig.i18n.defaultLocale,
      relativePath: [slug, tab]
    }
  }: PlaceRoutesContext) {
    const place = await lookupPlace(slug);
    const placeSlug = getPlacePrimarySlug(place);

    if (placeSlug && placeSlug !== slug) {
      return redirectToSlug(placeSlug, tab);
    }

    const [{ contentfulQuery }, extraPlaceDetailsData, placeAnnotationsData] = await Promise.all([
      loadContentfulPlaceDetailsData(normalizeLocale(locale)),
      callIfValidPlace(place, () => checkForExtraPlaceDetailData(place), {}),
      fetchaAndParsePlaceAnnotationsData(place)
    ]);

    return {
      props: {
        place,
        contentfulQuery,
        extraPlaceDetailsData,
        placeAnnotationsData
      }
    };
  }
};
