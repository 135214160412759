import { useClientSideEffect } from 'hooks/useClientSideEffect';
import { MapControllers } from 'models/MapControllers';
import { useCallback } from 'react';

export const useMapSourceEffectDispatcher = (mapControllerId: MapControllers) => {
  return useCallback(() => {
    const eventName = `map.${mapControllerId}.source.load`;

    document.dispatchEvent(
      new CustomEvent(eventName, {
        bubbles: false
      })
    );
  }, [mapControllerId]);
};

export const useMapSourceEffect = (callback: () => void, mapControllerId: MapControllers) => {
  useClientSideEffect(() => {
    const eventName = `map.${mapControllerId}.source.load`;

    document.addEventListener(eventName, callback);

    return () => {
      document.removeEventListener(eventName, callback);
    };
  });
};
