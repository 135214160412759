import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

export const TrackingLocationIcon = React.memo((props: GlyphProps) => (
  <Glyph width={25} height={25} {...props}>
    <g filter="url(#filter0_d_3083_661)">
      <circle cx="12.54" cy="12.54" r="10.54" transform="rotate(-90 12.54 12.54)" fill="white" />
      <circle
        cx="12.54"
        cy="12.54"
        r="10.04"
        transform="rotate(-90 12.54 12.54)"
        stroke="currentColor"
      />
    </g>
    <path
      d="M13.2898 7.50799L17.4145 15.8296C17.6955 16.3965 17.1287 17.0282 16.5331 16.8119L12.8488 15.4739C12.6903 15.4164 12.5152 15.4187 12.3559 15.4803L8.65082 16.9135C8.0519 17.1452 7.49442 16.528 7.78396 15.9537L12.0343 7.52418C12.2968 7.00354 13.035 6.99402 13.2898 7.50799Z"
      fill="currentColor"
    />
    <defs>
      <filter
        id="filter0_d_3083_661"
        x="0.76"
        y="0.76"
        width="23.56"
        height="23.56"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="0.62" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3083_661" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_3083_661"
          result="shape"
        />
      </filter>
    </defs>
  </Glyph>
));

TrackingLocationIcon.displayName = 'TrackingLocationIcon';
