import { ITrackingTripWaypoint, ITripWaypoint } from '@truckmap/common';
import { RoutePlannerDestinationIcon } from 'icons/routePlanner/RoutePlannerDestinationIcon';
import { RoutePlannerOriginIcon } from 'icons/routePlanner/RoutePlannerOriginIcon';
import { DestinationArrivedIcon } from 'icons/tracking/DestinationArrivedIcon';
import { DestinationIcon } from 'icons/tracking/DestinationIcon';
import { TrackingLocationIcon } from 'icons/tracking/TrackingLocationIcon';
import { getS3Url } from 'lib/image/getS3Url';
import { MarkerOptions } from 'mapbox-gl';
import { createRoot } from 'react-dom/client';

export type MakeMapMarkerProps = {
  icon?: string;
  lineType?: 'remaining' | 'history';
  background?: string;
  type?: ITripWaypoint['type'];
  render?: React.ReactNode;
} & MarkerOptions;

export const TRACKING_UPDATE_MAP_MARKER = 'tracking-update-marker';

export const makeRouteMapLabel = (label: string) => {
  return `
    <div class="shadow-lg rounded-lg flex-col flex py-1 px-2 bg-tmBlue border border-white mapbox-route-map-label">
      <div class="text-left flex font-semibold text-md text-white">
        ${label}
      </div>
    <div>
  `;
};

export const makeRouteMapMarker = (props: MakeMapMarkerProps) => {
  const image = props.icon ? getS3Url(props.icon, { width: 24, height: 24 }) : null;
  const el = document.createElement('div');
  const root = createRoot(el);

  if (props.render) {
    root.render(props.render);
    return el;
  }

  if (props.type === 'start') {
    root.render(<RoutePlannerOriginIcon size="3xl" />);
    return el;
  }
  if (props.type === 'end') {
    root.render(<RoutePlannerDestinationIcon size="3xl" />);
    return el;
  }

  el.className = 'w-6 h-6 border border-white rounded-full shadow-md bg-tmBlue';
  el.style.borderWidth = '3px';

  if (image) {
    el.style.backgroundImage = `url(${image})`;
    el.style.backgroundSize = '80%';
    el.style.backgroundPosition = 'center';
  }

  if (props.background) {
    el.style.backgroundColor = '#' + props.background;
  }

  return el;
};

export const makeTrackingMapMarker = ({
  isUpcoming = false,
  isUpdateMarker = false,
  orderName,
  ...props
}: Omit<MakeMapMarkerProps, 'type'> &
  Pick<ITrackingTripWaypoint, 'orderName'> & {
    isUpdateMarker?: boolean;
    isUpcoming?: boolean;
  }) => {
  const el = document.createElement('div');
  const root = createRoot(el);

  if (props.render) {
    root.render(props.render);
    return el;
  }

  if (isUpdateMarker) {
    return makeTrackingUpdateMarker();
  }

  return makeTrackingWaypointMarker({ isUpcoming, orderName });
};

export const makeTrackingUpdateMarker = () => {
  const el = document.createElement('div');
  const updateWaypointClassName = `w-3 h-3 rounded-full bg-gray-100 `;
  el.className = updateWaypointClassName + TRACKING_UPDATE_MAP_MARKER;
  el.style.borderWidth = '2px';
  el.style.borderColor = '#FFF';

  return el;
};

export const makeTrackingWaypointMarker = ({ isUpcoming, orderName }) => {
  const el = document.createElement('div');
  const root = createRoot(el);

  if (orderName === 'last') {
    const Icon = isUpcoming ? DestinationIcon : DestinationArrivedIcon;
    const iconSize = isUpcoming ? '4xl' : '5xl';

    root.render(<Icon size={iconSize} className="bg-brightBlue-600 text-brightBlue-600" />);

    return el;
  }

  const borderClassName = isUpcoming ? 'border-tmBlue' : 'border-gray-100';
  el.className = `w-5 h-5 border ${borderClassName} rounded-full shadow-md bg-white`;
  el.style.borderWidth = '6px';

  return el;
};

export const makeTrackingLocationMarker = (isActive = false) => {
  const el = document.createElement('div');
  const root = createRoot(el);

  const classes = `${isActive ? 'text-brightBlue-600' : 'text-primaryIcon'} mb-1/2 ml-1/2`;

  root.render(<TrackingLocationIcon size="3xl" className={classes} />);

  return el;
};
