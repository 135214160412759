import { ITrackingTripWaypoint, ITrackingUpdate } from '@truckmap/common';
import dynamic from 'next/dynamic';
import React from 'react';
import { TranslationsType } from 'types/common';

export enum TrackingTooltipType {
  EVENT = 'EVENT',
  WAYPOINT = 'WAYPOINT'
}

export const MapTooltip = {
  [TrackingTooltipType.EVENT]: dynamic(() =>
    import('components/Tracking/TrackingTooltip/TrackingUpdateTooltip').then(
      (mod) => mod.TrackingUpdateTooltip
    )
  ),
  [TrackingTooltipType.WAYPOINT]: dynamic(() =>
    import('components/Tracking/TrackingTooltip/TrackingWaypointTooltip').then(
      (mod) => mod.TrackingWaypointTooltip
    )
  )
};

export type TrackingTooltipProps = {
  type: TrackingTooltipType;
  waypoint: ITrackingTripWaypoint;
  update: ITrackingUpdate;
  translations: TranslationsType;
} & ParentClassNameProp;

export const TrackingTooltip: React.FC<TrackingTooltipProps> = React.memo((props) => {
  const { type, waypoint, update, translations, ...restProps } = props;

  const Element = MapTooltip[type];

  return <Element waypoint={waypoint} update={update} translations={translations} {...restProps} />;
});

TrackingTooltip.displayName = 'TrackingTooltip';
