import { IPlace, ITrip, LatLon } from '@truckmap/common';
import { lookupPlace } from 'clients/place/placeDetailsMiddleware';
import { MapControllers } from 'models/MapControllers';
import { nanoid } from 'nanoid';
import { atomFamily, selectorFamily } from 'recoil';
import {
  setDefaultDestinationEffect,
  updateTripParamsEffect
} from 'recoil/routePlanner/routePlannerAtomEffects';
import { truckMapConfig } from 'truckMapConfig';

const isTenstreet = truckMapConfig.isTenstreet;

export type EmptyWaypoint = {
  id: string;
  name: string;
  objectType?: string;
  disabled?: boolean;
  default?: boolean;
  lonlat?: {
    coordinates?: [number, number];
  };
};

export type DefaultLocationWaypoint = {
  id: string;
  name: string;
  objectType: 'currentLocation' | 'destination';
  lat: number;
  lon: number;
  default?: boolean;
};

export type WaypointRecoilType = EmptyWaypoint | DefaultLocationWaypoint;

export type RoutePlannerAtom = {
  waypoints: WaypointRecoilType[];
  tripId?: string;
  tripResultId?: string;
  hoveredTripResultId?: string;
  currentTrip?: ITrip;
  isCreatingTrip?: boolean;
  hasExpandedWaypoints?: boolean;
  isAddingWaypoint?: boolean;
  isEditingOriginDestination?: boolean;
  initialCoords?: LatLon;
  nearbyPlace?: IPlace;
};

export const defaultWaypoints = [
  {
    id: nanoid(),
    name: ''
  },
  {
    id: nanoid(),
    name: '',
    default: true
  }
];

export const getDestinationPlace = selectorFamily({
  key: 'getDestinationPlace',
  get: (address: string) => async () => {
    const response = await lookupPlace(address);
    return response;
  }
});

export const routePlannerAtom = atomFamily<RoutePlannerAtom, string>({
  key: 'routePlannerAtom',
  default: {
    tripId: null,
    tripResultId: null,
    hoveredTripResultId: null,
    waypoints: defaultWaypoints,
    isCreatingTrip: false,
    hasExpandedWaypoints: true,
    isAddingWaypoint: false,
    isEditingOriginDestination: false
  },
  effects: isTenstreet
    ? (controllerId) =>
        controllerId === MapControllers.ROUTE_PLANNER ? [setDefaultDestinationEffect] : []
    : [updateTripParamsEffect]
});
