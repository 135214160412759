import { ContentfulCacheConfig, queryContentfulCache } from 'clients/contentful/contentfulCache';
import { truckMapConfig } from 'truckMapConfig';
import { GeneratedSectionLayouts, TranslationDataTypes } from 'types/translationKeys';

const TENSTREET_CTA_SECTION = 'tenstreet-cta-places-search';

type AvailableQueryVariables = {
  locale: string;
  translationKeys?: string[];
  commonCollectionLimit?: number;
  uiItemsLimit?: number;
  uiComponentsLimit?: number;
  sectionLayouts?: GeneratedSectionLayouts[];
  sectionLayout?: string;
};

type GenericContentfulQueryVariables = Pick<AvailableQueryVariables, 'locale' | 'sectionLayouts'>;

type ContentfulQueryVariablesParams<V = GenericContentfulQueryVariables> = {
  variables?: V;
} & Omit<ContentfulCacheConfig, 'variables'>;

const genericDataTypes: TranslationDataTypes[] = [
  'place_type',
  'place_section',
  'place_review_section_type',
  'place_review_item_type',
  'place_meta_option'
];

const genericSections: GeneratedSectionLayouts[] = !truckMapConfig.isTenstreet
  ? ['public-navbar', 'public-footer']
  : [];

const genericPlaceSections: GeneratedSectionLayouts[] = !truckMapConfig.isTenstreet
  ? [...genericSections, TENSTREET_CTA_SECTION]
  : [];

export async function loadContentfulGenericData({
  variables,
  legacyRelativePath,
  queryName = 'publicPages'
}: ContentfulQueryVariablesParams) {
  const {
    contentfulQueryResponse: { data: contentfulQuery, errors },
    legacyLayoutResponse
  } = await queryContentfulCache({
    queryName,
    legacyRelativePath,
    variables: {
      sectionLayouts: genericSections,
      ...variables
    }
  });

  if (errors?.length) {
    throw errors;
  }

  return { contentfulQuery, legacyLayoutResponse };
}

export async function loadContentfulPrivatePagesData(variables?: GenericContentfulQueryVariables) {
  const {
    contentfulQueryResponse: { data: contentfulQuery, errors }
  } = await queryContentfulCache({
    queryName: 'privatePages',
    variables
  });

  if (errors?.length) {
    throw errors;
  }

  return { contentfulQuery };
}

type PlacePagesQueryVariables = GenericContentfulQueryVariables & {
  dataTypes: TranslationDataTypes[];
};

export async function loadContentfulPlacePagesData(
  config: ContentfulQueryVariablesParams<PlacePagesQueryVariables>
) {
  return loadContentfulGenericData({ ...config, queryName: 'placeDetails' });
}

export async function loadContentfulDashboardPagesData(variables: GenericContentfulQueryVariables) {
  return loadContentfulGenericData({
    variables: {
      sectionLayouts: ['route-planner'],
      ...variables
    }
  });
}

export async function loadContentfulTranslationData(locale: string) {
  return loadContentfulPlacePagesData({
    variables: {
      locale,
      dataTypes: genericDataTypes
    }
  });
}

export async function loadContentfulPlaceDetailsData(locale: string) {
  return loadContentfulPlacePagesData({
    variables: {
      locale,
      dataTypes: genericDataTypes,
      sectionLayouts: genericPlaceSections
    }
  });
}

export async function loadContentfulPlacesSearchData(locale: string) {
  return loadContentfulPlacePagesData({
    variables: {
      locale,
      dataTypes: ['place_meta_type', 'place_type'],
      sectionLayouts: genericPlaceSections
    }
  });
}
