import { ITrackingTripWaypoint, ITrackingUpdate } from '@truckmap/common';
import {
  TrackingTooltip,
  TrackingTooltipType
} from 'components/Tracking/TrackingTooltip/TrackingTooltip';
import { TrackingUnattachedLineTooltip } from 'components/Tracking/TrackingTooltip/TrackingUnattachedLineTooltip';
import { renderToStaticMarkup } from 'react-dom/server';
import { TranslationsType } from 'types/common';

export type Props = {
  text?: string;
};

export const makeRouteMapMarkerTooltip = (props: Props) => {
  return `
    <MetaText as="h3" class="shadow-lg rounded-md bg-white border border-primaryBorder py-1 px-2">
      ${props.text}
    </MetaText>
  `;
};

export const makeTrackingMapMarkerTooltip = ({
  marker,
  type,
  translations
}: {
  marker?: ITrackingUpdate | ITrackingTripWaypoint;
  type?: TrackingTooltipType;
  translations: TranslationsType;
}) => {
  const tooltip = renderToStaticMarkup(
    <TrackingTooltip
      update={marker as ITrackingUpdate}
      waypoint={marker as ITrackingTripWaypoint}
      type={type}
      translations={translations}
    />
  );

  return tooltip;
};

export const makeTrackingUnattachedLineTooltip = (translations: TranslationsType) => {
  const tooltip = renderToStaticMarkup(
    <TrackingUnattachedLineTooltip translations={translations} />
  );

  return tooltip;
};
