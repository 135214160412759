import { buildLatLon, ITrip } from '@truckmap/common';
import { makeRouteMapMarker } from 'lib/map/makeMapMarkers';
import { makeMapMarkerStartRotation } from 'lib/map/makeMapMarkerStartRotation';
import { makeRouteMapMarkerTooltip } from 'lib/map/makeMapMarkerTooltips';
import { getTripPositions } from 'lib/routeUtils';
import mapboxgl, { Marker } from 'mapbox-gl';
import { SetStateAction } from 'react';

import { removeMapboxPopupStyle } from './removeMapboxPopupStyle';

export function removeMarkers(markers: Marker[]) {
  return markers.forEach((marker) => {
    marker.remove();
  });
}

const getMapBounds = (positions) =>
  positions.reduce(
    (bounds, coord) => bounds.extend(coord),
    new mapboxgl.LngLatBounds(positions[0], positions[0])
  );

export type HandleTripMarkersProps = {
  mapInstance: mapboxgl.Map;
  trip: ITrip;
  setBounds?: {
    (value: SetStateAction<[number, number, number, number]>): void;
    (arg0: [number, number, number, number]): void;
  };
  markers: Marker[];
  makeMapMarkerTooltip?: (props) => string;
  makeMapMarker?: (props) => HTMLDivElement;
  disableStartRotation?: boolean;
  disablePopup?: boolean;
};

export const handleTripMarkers = ({
  mapInstance,
  trip,
  setBounds,
  markers = [],
  makeMapMarkerTooltip = makeRouteMapMarkerTooltip,
  makeMapMarker = makeRouteMapMarker,
  disableStartRotation = false,
  disablePopup = false
}: HandleTripMarkersProps) => {
  const lats = [];
  const lons = [];
  removeMarkers(markers);

  if (!trip) return;
  const positions = getTripPositions(trip);

  for (const marker of trip.tripWaypoints) {
    const company = marker.place?.companies?.length && marker.place.companies[0];
    let options = {};
    if (company) {
      const { asset } = company;
      options = {
        icon: asset.remoteIconPath,
        background: asset.mapIconBackgroundColor
      };
    }

    const el = makeMapMarker({
      ...options,
      type: marker.type
    });

    const { longitude, latitude } = buildLatLon(marker);
    const rotation = makeMapMarkerStartRotation(positions);

    const mapMarker = new mapboxgl.Marker({
      element: el,
      rotationAlignment: 'viewport',
      ...(!disableStartRotation && marker.type === 'start' && { rotation })
    });

    let popup: mapboxgl.Popup;

    if (marker && !disablePopup) {
      popup = new mapboxgl.Popup({
        anchor: 'left',
        offset: [18, -2],
        closeButton: false,
        closeOnClick: false
      });
      popup.setHTML(makeMapMarkerTooltip({ text: marker?.place?.name }));

      mapMarker.setPopup(popup);
    }

    const markerCoords: [number, number] | [] =
      marker.type === 'start'
        ? positions?.length && positions[0]
          ? [positions[0][0], positions[0][1]]
          : []
        : [longitude, latitude];

    if (markerCoords.length && mapInstance) {
      mapMarker.setLngLat(markerCoords).addTo(mapInstance);
      markers.push(mapMarker);

      if (popup && !disablePopup) {
        mapMarker.getElement().addEventListener('mouseenter', () => {
          popup.setLngLat(markerCoords).addTo(mapInstance);
          removeMapboxPopupStyle({});
        });
        mapMarker.getElement().addEventListener('mouseleave', () => {
          popup.remove();
        });
      }
    }

    lats.push(latitude);
    lons.push(longitude);
  }

  setBounds && setBounds(getMapBounds(positions));

  return markers;
};
