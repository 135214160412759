import { PlaceMetaTypeSectionList } from '@truckmap/common';

const validPlacePageSections = ['published', 'pending', 'empty'];

export function filterPageSections(sections: PlaceMetaTypeSectionList): PlaceMetaTypeSectionList {
  return sections.reduce<PlaceMetaTypeSectionList>((validSections, section) => {
    if (validPlacePageSections.find((status) => section[status]?.length)) {
      return validSections.concat(section);
    }
    return validSections;
  }, []);
}
