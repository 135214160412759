import {
  createTrip,
  getTripLegResultsMarkerPositions,
  IPlace,
  ITrip,
  ITripInstruction,
  ITripLeg,
  IUserMeta,
  prettyDurationShort,
  updateTripResult
} from '@truckmap/common';
import bboxPolygon from '@turf/bbox-polygon';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { feature, featureCollection, Id, lineString, point, Position } from '@turf/helpers';
import { globalAlert } from 'components/common/Alert/AlertGlobalHotToast';
import { BBox, Feature, LineString } from 'geojson';
import { TranslationConfig } from 'hooks/useContentful';
import { getMapInstance, TruckMapGL } from 'hooks/useMap/useMapGL/MapGLContext';
import { produce } from 'immer';
import { pingEvent } from 'lib/analytics/event';
import { makeRouteMapLabel } from 'lib/map/makeMapMarkers';
import { removeMapboxPopupStyle } from 'lib/map/removeMapboxPopupStyle';
import { SerializedWaypoint, serializeWaypoints } from 'lib/routePlanner/serializeWaypoint';
import { updateTripUrl } from 'lib/routePlanner/updateTripUrl';
import { GeoJSONSource } from 'mapbox-gl';
import AnimatedPopup from 'mapbox-gl-animated-popup';
import { MapControllers } from 'models/MapControllers';
import { SetterOrUpdater } from 'recoil';
import { DispatchAtom } from 'recoil/dispatch/dispatchAtom';
import { Waypoint } from 'recoil/routePlanner';
import {
  DefaultLocationWaypoint,
  EmptyWaypoint,
  RoutePlannerAtom
} from 'recoil/routePlanner/routePlannerAtom';
import { TrackingAtom } from 'recoil/tracking/trackingAtom';
import { truckMapConfig } from 'truckMapConfig';
import { TranslationKeys } from 'types/translationKeys';
import { validate as uuidValidate, version as uuidVersion } from 'uuid';

export const isPlaceOnRoute = (placeId: string, waypoints: Waypoint[]) =>
  waypoints.some((waypoint) => waypoint.id === placeId);

export const isRouteZoomOutOfBounds = (positions: Position[], mapInstance: TruckMapGL) =>
  positions?.length < 400 && mapInstance.getZoom() < 9;

export const isRouteOutOfBounds = (boundBox, positions) => {
  if (!boundBox) return false;

  const boundBoxPolygon = bboxPolygon(boundBox);

  return positions?.some((position) => {
    const currentPoint = point(position);
    return !booleanPointInPolygon(currentPoint, boundBoxPolygon);
  });
};

export const simplifyOutOfBoundsCoordinates = (
  boundBox,
  thresholdStep = 10,
  intervalRemovalStep = 2
) => {
  /*
   * Threshold:
   Used to determinate when the coordinates will start to be removed,
   the default threshold value is 10, so the coordinates will be removed after the 10th coordinate outside the box.
    
   * Interval:
   Interval between the coordinates outside the box that will be removed, 
   the default value is 2 so the coordinates after the threshold will be remove 2 by 2.
  */

  if (thresholdStep === 0) return () => true;

  let currentStep = 0;
  let currentThresholdStep = 0;

  return (coord) => {
    const [lon, lat] = coord;
    const currentPoint = point([lon, lat]);

    if (booleanPointInPolygon(currentPoint, boundBox)) {
      return true;
    } else {
      if (currentThresholdStep >= thresholdStep) {
        currentStep++;
        if (currentStep === intervalRemovalStep) {
          currentStep = 0;
          return false;
        }
      }

      currentThresholdStep++;
      return true;
    }
  };
};

export function uuidValidateV4(uuid: string) {
  return uuidValidate(uuid) && uuidVersion(uuid) === 4;
}

export function hasTripRequirements(waypoints: Waypoint[]) {
  return (
    waypoints.filter(
      (currentWaypoint) =>
        ((currentWaypoint as IPlace)?.longitude && (currentWaypoint as IPlace)?.latitude) ||
        (currentWaypoint as EmptyWaypoint)?.lonlat?.coordinates?.length ||
        // TODO: update CurrentLocationWaypoint type to be longitude and latitude default format
        ((currentWaypoint as DefaultLocationWaypoint)?.lon &&
          (currentWaypoint as DefaultLocationWaypoint)?.lat)
    ).length >= 2
  );
}

export function originAndDestinationAreEqual(waypoints: SerializedWaypoint[]) {
  const origin = waypoints[0];
  const destination = waypoints[waypoints.length - 1];

  return origin && destination && origin.lat === destination.lat && origin.lon === destination.lon;
}

export const createNewTripHandler = ({
  waypoints,
  setRoute,
  userMetas,
  t
}: {
  waypoints: Waypoint[];
  setRoute: SetterOrUpdater<RoutePlannerAtom>;
  userMetas: Partial<IUserMeta>;
  t: (key: TranslationKeys, config?: TranslationConfig) => string;
}) => {
  if (hasTripRequirements(waypoints)) {
    const serializedWaypoints = serializeWaypoints(waypoints);

    if (serializedWaypoints.length >= 2) {
      if (originAndDestinationAreEqual(serializedWaypoints)) {
        globalAlert({
          intent: 'danger',
          message: t('ORIGIN_DESTINATION_SAME')
        });

        setRoute(
          produce((draft) => {
            draft.isCreatingTrip = false;
          })
        );

        return;
      }

      setRoute(
        produce((draft) => {
          draft.isCreatingTrip = true;
        })
      );

      pingEvent('CREATE_TRIP', { waypoints: serializedWaypoints.length });

      createTrip({
        waypoints: serializedWaypoints,
        clientVersion: truckMapConfig.appVersion,
        ...userMetas
      })
        .then((newTrip) => {
          if (newTrip?.id) {
            pingEvent('CREATE_TRIP_SUCCESS', {
              tripId: newTrip.id
            });

            updateTripUrl(newTrip.id);
          }
        })
        .catch((err) => {
          pingEvent('CREATE_TRIP_ERROR');
          globalAlert({
            intent: 'danger',
            message: t('ERROR_GETTING_ROUTES')
          });
          console.error(err);
        })
        .finally(() => {
          setRoute(
            produce((draft) => {
              draft.isCreatingTrip = false;
            })
          );
        });
    }
  }
};

export function getCurrentTripRevision(trip: ITrip) {
  return trip?.tripRevisions?.find(({ id }) => id === trip.tripRevisionId);
}

export function getCurrentTripLegResult(leg: ITripLeg) {
  return leg?.tripResults?.find(({ id }) => id === leg.tripResultId);
}

export function getTripPositions(trip: ITrip) {
  const positions = getCurrentTripRevision(trip)
    ?.tripLegs?.map((leg) => getCurrentTripLegResult(leg)?.coordinates)
    .flat();
  return positions;
}

export function getTripLegByTripResultId(
  tripLegs: ITripLeg[],
  tripResultId: string
): ITripLeg | undefined {
  const tripLeg = tripLegs.find((tripLeg) =>
    tripLeg.tripResults.some((tripResult) => tripResult.id === tripResultId)
  );

  return tripLeg;
}

export function getAllTripResults(trip: ITrip) {
  if (!trip) return [];

  const tripLegs = getCurrentTripRevision(trip)?.tripLegs;

  return tripLegs.map((leg) => {
    if (leg.order !== 0) {
      return leg.tripResults.map((result) => ({
        id: result.id,
        order: 0,
        coordinates: result.coordinates
      }));
    }

    return leg.tripResults.map((result) => ({
      id: result.id,
      order: result.order,
      coordinates: result.coordinates
    }));
  });
}

export function getTripLegsLabelPositions(trip: ITrip) {
  const tripResults = getCurrentTripRevision(trip)?.tripLegs.flatMap(
    ({ tripResults }) => tripResults
  );

  return tripResults?.map((result, index) => ({
    id: result.id,
    order: result.order,
    coordinates: getTripLegResultsMarkerPositions(tripResults)[index],
    duration: result.duration
  }));
}

const MAP_ZOOM_THRESHOLD = 7;

export const generateTripSource = (
  positions: Position[],
  bounds: BBox,
  zoom: number,
  activeDirectionValue,
  tripId?: string,
  extraConfig?: { order?: string; bbox?: BBox; id?: Id }
) => {
  const line = lineString(positions);
  const boundBox = bboxPolygon(bounds);

  line.geometry.coordinates = line.geometry.coordinates.filter(
    simplifyOutOfBoundsCoordinates(boundBox, zoom > MAP_ZOOM_THRESHOLD && 0)
  );

  return feature(
    line.geometry,
    { hasTrip: !!tripId, ...(extraConfig && extraConfig) },
    {
      ...(extraConfig?.id && {
        id: extraConfig?.id
      })
    }
  );
};

export function handleSources({
  names,
  controllerId,
  sources,
  reverse
}: {
  names: string[];
  controllerId: MapControllers;
  sources: ((mapInstance: TruckMapGL) => Feature<LineString, unknown>[])[];
  reverse?: boolean;
}) {
  if (!sources.length) return;
  const mapInstance = getMapInstance(controllerId);
  if (!mapInstance) return;

  names.forEach((source, index) => {
    if (mapInstance.getSource(source)) {
      (mapInstance.getSource(source) as GeoJSONSource).setData(
        featureCollection(
          reverse ? sources[index]?.(mapInstance)?.reverse() : sources[index](mapInstance)
        )
      );
    }
  });
}

export function generateSourcesWithAlternateRoutes({
  tripId,
  mapInstance,
  mapAtom,
  setMapAtom,
  positions,
  activeDirectionValue,
  zoom
}: {
  tripId: string;
  mapInstance: TruckMapGL;
  mapAtom: RoutePlannerAtom | DispatchAtom | TrackingAtom;
  setMapAtom: SetterOrUpdater<RoutePlannerAtom | DispatchAtom | TrackingAtom>;
  positions: {
    id: string;
    order: number;
    coordinates: Position[];
  }[][];
  activeDirectionValue?: ITripInstruction;
  zoom?: number;
}) {
  const tripResultId = mapAtom.hoveredTripResultId || mapAtom.tripResultId;
  const [, ...otherLegs] = positions;

  if (!positions.length) return;

  const orderedPositions =
    positions[0]?.reduce((acc, position) => {
      if (position.id !== tripResultId) {
        acc.push(position);
      } else {
        acc.unshift(position);
      }

      return acc;
    }, []) || [];

  if (!mapAtom.hoveredTripResultId && orderedPositions[0]) {
    setMapAtom(
      produce(mapAtom, (draft) => {
        draft.hoveredTripResultId = orderedPositions[0].id;
      })
    );
  }

  let allPositions = orderedPositions.map((position, index) => ({ ...position, order: index }));

  if (otherLegs?.length) {
    allPositions = [
      ...allPositions,
      ...otherLegs.flatMap((leg) => leg.map((pos) => ({ ...pos, order: 0 })))
    ];
  }

  return (
    allPositions?.length &&
    allPositions.map(({ coordinates, id, order }) =>
      generateTripSource(
        coordinates,
        mapInstance.getBounds().toArray().flat() as BBox,
        zoom || mapInstance.getZoom(),
        activeDirectionValue && activeDirectionValue,
        tripId,
        {
          id,
          order: String(order)
        }
      )
    )
  );
}

export function addTripLegHoverHandler(
  mapInstance: TruckMapGL,
  setMapAtom: SetterOrUpdater<RoutePlannerAtom | DispatchAtom | TrackingAtom>,
  mapLayer: string
) {
  mapInstance.on('mouseenter', mapLayer, (e) => {
    mapInstance.getCanvas().style.cursor = 'pointer';

    if (e.features.length > 0) {
      setMapAtom(
        produce((draft) => {
          draft.hoveredTripResultId = String(e.features[0]?.properties.id);
        })
      );
    }
  });
  mapInstance.on('mouseleave', mapLayer, () => {
    mapInstance.getCanvas().style.cursor = 'grab';

    setMapAtom(
      produce((draft) => {
        draft.hoveredTripResultId = null;
      })
    );
  });
}

export function addUpdateTripOnClickHandler({
  mapInstance,
  setMapAtom,
  mapLayer,
  legs,
  translation,
  callback
}: {
  mapInstance: TruckMapGL;
  setMapAtom: SetterOrUpdater<RoutePlannerAtom | DispatchAtom | TrackingAtom>;
  mapLayer: string;
  legs: ITripLeg[];
  translation: (key: TranslationKeys, config?: TranslationConfig) => string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback?: (e: any, tripLeg: ITripLeg) => Promise<void>;
}) {
  mapInstance.on('click', mapLayer, async (e) => {
    e.preventDefault();

    const features = mapInstance
      .queryRenderedFeatures(e.point)
      .filter((feature) => feature.layer.id === mapLayer)
      .map((feature) => feature.properties.id);

    const tripResultId = features[0];
    const tripLeg = getTripLegByTripResultId(legs, tripResultId);

    if (!tripLeg) return;

    try {
      await updateTripResult({
        tripResultId,
        tripLegId: tripLeg.id
      }).then((newTripLeg) => {
        setMapAtom(
          produce((draft) => {
            draft.tripResultId = newTripLeg.tripResultId;
            draft.hoveredTripResultId = newTripLeg.id;
          })
        );

        callback && callback(e, newTripLeg);

        return;
      });
    } catch (e) {
      globalAlert({
        intent: 'danger',
        message: translation('FAILED_TO_UPDATE_TRIP_RESULT'),
        isSmall: true
      });
    }
  });
}

export function addLabelsToTripLegs(mapInstance: TruckMapGL, trip: ITrip) {
  const labelPositions = getTripLegsLabelPositions(trip);

  const popups = document.querySelectorAll('.mapbox-route-map-label');

  if (popups.length) {
    popups.forEach((element) => {
      element.remove();
    });
  }

  labelPositions?.forEach((pos) => {
    const popup = new AnimatedPopup({
      offset: [0, -10],
      closeButton: false,
      closeOnClick: false,
      openingAnimation: {
        duration: 1000,
        easing: 'easeOutQuint',
        transform: 'opacity'
      },
      closingAnimation: {
        duration: 300,
        easing: 'easeOutQuint',
        transform: 'opacity'
      }
    });

    popup.setHTML(makeRouteMapLabel(prettyDurationShort(pos.duration)));
    popup.setLngLat(pos.coordinates).addTo(mapInstance);
    removeMapboxPopupStyle({});
  });
}

export function resetMapUI() {
  const labelPopups = document.querySelectorAll('.mapbox-route-map-label');
  const mapMarkers = document.querySelectorAll('.mapboxgl-marker');

  if (labelPopups.length) {
    labelPopups.forEach((element) => {
      element.remove();
    });
  }

  if (mapMarkers.length) {
    mapMarkers.forEach((element) => {
      element.remove();
    });
  }
}
