import { locationInnerDotMapId } from 'icons/routePlanner/locationInnerDotAnimation';
import { locationRingsMapId } from 'icons/routePlanner/locationRingsAnimation';
import { colors } from 'lib/colors/getColors';

import { RoutePlannerMapSources } from './useRoutePlannerMapSource';

const routeFilter = ['all', ['has', 'hasTrip'], ['==', 'hasTrip', true]];
const preciseLocationFilter = ['all', ['!has', 'tripId'], ['==', 'precise', true]];
const unpreciseLocationFilter = ['all', ['!has', 'tripId'], ['!=', 'precise', true]];
const activeDirectionFilter = ['all', ['has', 'isActive'], ['==', 'isActive', true]];

export enum RoutePlannerMapLayers {
  ROUTE = 'route',
  PRECISE_LOCATION = 'precise_location',
  UNPRECISE_LOCATION = 'unprecise_location',
  UNPRECISE_LOCATION_BG = 'unprecise_location_bg',
  ACTIVE_DIRECTION = 'active_direction'
}

export const useRoutePlannerMapLayers = () => [
  {
    id: RoutePlannerMapLayers.ROUTE,
    type: 'line',
    filter: routeFilter,
    source: RoutePlannerMapSources.ROUTE,
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': [
        'match',
        ['get', 'order'],
        '0',
        colors.blue800,
        '1',
        colors.gray200,
        '2',
        '#8b9aaa',
        '3',
        '#eeeff1',
        '#eeeff1'
      ],
      'line-width': [
        'interpolate',
        ['exponential', 2],
        ['zoom'],
        7,
        ['match', ['get', 'order'], '0', 8, 5],
        15,
        ['match', ['get', 'order'], '0', 12, 10]
      ]
    }
  },
  {
    id: RoutePlannerMapLayers.UNPRECISE_LOCATION_BG,
    source: RoutePlannerMapSources.CURRENT_LOCATION_RADIUS,
    type: 'fill',
    filter: unpreciseLocationFilter,
    paint: {
      'fill-color': colors.gray800,
      'fill-opacity': 0.15
    }
  },
  {
    id: RoutePlannerMapLayers.UNPRECISE_LOCATION,
    source: RoutePlannerMapSources.CURRENT_LOCATION,
    type: 'circle',
    filter: unpreciseLocationFilter,
    paint: {
      'circle-color': colors.gray100,
      'circle-radius': 8,
      'circle-stroke-color': colors.white,
      'circle-stroke-width': 3
    }
  },
  {
    id: RoutePlannerMapLayers.PRECISE_LOCATION,
    source: RoutePlannerMapSources.CURRENT_LOCATION,
    type: 'symbol',
    filter: preciseLocationFilter,
    layout: {
      'icon-image': locationInnerDotMapId
    }
  },
  {
    id: RoutePlannerMapLayers.ACTIVE_DIRECTION,
    type: 'symbol',
    source: RoutePlannerMapSources.ACTIVE_DIRECTION,
    filter: activeDirectionFilter,
    layout: {
      'icon-image': locationRingsMapId
    }
  }
];
