import { produce } from 'immer';
import { removeTripCookie, updateTripCookie } from 'lib/routePlanner/tripCookie';
import { updateTripUrl } from 'lib/routePlanner/updateTripUrl';
import { nanoid } from 'nanoid';
import { AtomEffect } from 'recoil';
import { getDestinationPlace, RoutePlannerAtom } from 'recoil/routePlanner/routePlannerAtom';

export const updateTripParamsEffect: AtomEffect<RoutePlannerAtom> = ({ onSet }) => {
  onSet((newRoutePlannerAtom, oldValue, isReset) => {
    if (newRoutePlannerAtom.tripId) {
      updateTripCookie(newRoutePlannerAtom.tripId);
      updateTripUrl(newRoutePlannerAtom.tripId);
    } else if (isReset) {
      removeTripCookie();
      updateTripUrl();
    }
  });
};

export const setDefaultDestinationEffect: AtomEffect<RoutePlannerAtom> = ({
  setSelf,
  trigger,
  getPromise
}) => {
  if (trigger === 'get' && typeof window !== 'undefined') {
    const searchParams = new URL(location?.href).searchParams;
    const destination = searchParams.get('destination');
    if (!destination) return;

    getPromise(getDestinationPlace(destination)).then((place) => {
      setSelf(
        produce((draft) => {
          const lastWaypoint = draft.waypoints[draft.waypoints.length - 1];

          if (lastWaypoint.default && place.name) {
            const lastItemIndex = draft.waypoints.length - 1;
            draft.waypoints[lastItemIndex] = {
              ...place,
              ...(!place?.id && { id: nanoid() }),
              placeTypeId: place.placeType?.id,
              objectType: 'destination'
            };
          }

          return draft;
        })
      );
    });
  }
};
