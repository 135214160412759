import Cookies from 'js-cookie';
import { truckMapConfig } from 'truckMapConfig';

// client side only, do not use on node.js or vercel middleware
export function updateTripCookie(tripId: string) {
  if (
    truckMapConfig.isClientSideRender &&
    Cookies.get(truckMapConfig.cookies.currentTripId) !== tripId
  ) {
    Cookies.set(truckMapConfig.cookies.currentTripId, tripId);
  }
}

export function getTripCookie() {
  return Cookies.get(truckMapConfig.cookies.currentTripId);
}

export function removeTripCookie() {
  return Cookies.remove(truckMapConfig.cookies.currentTripId);
}
