import { SmallText } from 'components/common/Typography';
import {
  TrackingTooltipCard,
  TrackingTooltipCardHeader
} from 'components/Tracking/TrackingTooltip/TrackingTooltipCard';
import { TrackingWaypointTooltipProps } from 'components/Tracking/TrackingTooltip/TrackingWaypointTooltip';
import React from 'react';

export const TrackingUnattachedLineTooltip: React.FC<
  Pick<TrackingWaypointTooltipProps, 'translations'>
> = React.memo(({ translations: t }: TrackingWaypointTooltipProps) => {
  return (
    <TrackingTooltipCard
      intent="neutral"
      paddingX="XS"
      className="w-min min-w-[180px] text-left"
      justify="start">
      <TrackingTooltipCardHeader
        className="w-full pb-1"
        title={<SmallText color="primary">{t('UNATTACHED_LINE_TOOLTIP')}</SmallText>}
      />
    </TrackingTooltipCard>
  );
});

TrackingUnattachedLineTooltip.displayName = 'TrackingUnattachedLineTooltip';
