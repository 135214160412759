import {
  fetchPlaceAnnotations,
  fetchPlaceAnnotationTypes,
  IPlace,
  IPlaceAnnotation,
  IPlaceAnnotationType
} from '@truckmap/common';

export async function fetchaAndParsePlaceAnnotationsData(place: IPlace): Promise<{
  placeAnnotations: (IPlaceAnnotation & { placeAnnotationType: IPlaceAnnotationType })[];
  placeAnnotationTypesGrouped?: {
    [key: string]: IPlaceAnnotationType[];
  };
  placeAnnotationTypes?: IPlaceAnnotationType[];
}> {
  const [placeAnnotations, placeAnnotationTypes] = await Promise.all([
    fetchPlaceAnnotations(place.id),
    fetchPlaceAnnotationTypes(place.id)
  ]);

  if (placeAnnotationTypes.length) {
    const placeAnnotationTypesGrouped = placeAnnotationTypes.reduce<{
      [key: string]: IPlaceAnnotationType[];
    }>((acc, curr) => {
      if (acc[curr.group]) {
        acc[curr.group].push(curr);
      } else {
        acc[curr.group] = [curr];
      }
      return acc;
    }, {});

    if (placeAnnotationTypes.length && placeAnnotations.length) {
      placeAnnotations.forEach((annotation) => {
        annotation.placeAnnotationType =
          placeAnnotationTypes.find((type) => type.id === annotation.placeAnnotationTypeId) || null;
      });
    }

    return {
      placeAnnotations,
      placeAnnotationTypesGrouped,
      placeAnnotationTypes
    };
  }

  return {
    placeAnnotations
  };
}
