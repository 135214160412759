import { buildApiPlaceObject, buildLatLon, IPlace, PlaceObjectApi } from '@truckmap/common';
import { uuidValidateV4 } from 'lib/routeUtils';
import { Waypoint } from 'recoil/routePlanner';

export type SerializedWaypoint =
  | {
      placeId: string;
      placeObject?: PlaceObjectApi;
      lon: number;
      lat: number;
    }
  | {
      placeId?: string;
      placeObject: PlaceObjectApi;
      lon: number;
      lat: number;
    };

const isEmptyWaypoint = (waypoint: IPlace | Waypoint) => Object.keys(waypoint).length < 3;

export const serializeWaypoint = (waypoint: IPlace | Waypoint): SerializedWaypoint => {
  if (isEmptyWaypoint(waypoint)) return;
  const { latitude: lat, longitude: lon } = buildLatLon(waypoint);

  return waypoint.id && uuidValidateV4(waypoint.id)
    ? { placeId: waypoint.id, lon, lat }
    : { placeObject: buildApiPlaceObject(waypoint), lon, lat };
};

export const serializeWaypoints = (waypoints: Array<IPlace | Waypoint>): SerializedWaypoint[] =>
  waypoints
    .map((w: IPlace | Waypoint) => serializeWaypoint(w))
    .filter((w) => w?.placeId || w?.placeObject);
