import { point } from '@turf/helpers';
import { Feature, Point } from 'geojson';
import { getMapInstance } from 'hooks/useMap/useMapGL/MapGLContext';
import { useMapSourceEffect } from 'hooks/useMap/useMapSource/useMapSourceEffect';
import {
  createCurrentLocationRadiusSource,
  RoutePlannerMapSources
} from 'hooks/useMap/useRoutePlannerMap/useRoutePlannerMapSource';
import { LatLonWithPreciseInfo } from 'lib/map/getGeolocation';
import { GeoJSONSource } from 'mapbox-gl';
import { MapControllers } from 'models/MapControllers';
import { useEffect, useMemo, useRef } from 'react';

export type RoutePlannerCurrentLocationGeoFeature = Feature<
  Point,
  {
    tripId: string;
    precise: boolean;
  }
>;

export type RoutePlannerCurrentLocationGeoConfig = {
  controllerId: MapControllers;
  tripId?: string;
} & LatLonWithPreciseInfo;

export function updateRoutePlannerCurrentLocationSource(
  controllerId: MapControllers,
  currentLocationGeoPoint: RoutePlannerCurrentLocationGeoFeature
) {
  const mapInstance = getMapInstance(controllerId);
  if (mapInstance) {
    const currentLocationSource = mapInstance.getSource(
      RoutePlannerMapSources.CURRENT_LOCATION
    ) as GeoJSONSource;

    const currentLocationSourceRadius = mapInstance.getSource(
      RoutePlannerMapSources.CURRENT_LOCATION_RADIUS
    ) as GeoJSONSource;

    if (currentLocationSource && currentLocationGeoPoint && currentLocationSourceRadius) {
      currentLocationSource.setData(currentLocationGeoPoint);

      currentLocationSourceRadius.setData(
        createCurrentLocationRadiusSource(currentLocationGeoPoint)
      );
    }
  }
}

export function useRoutePlannerCurrentLocationSourceEffect({
  controllerId,
  longitude,
  latitude,
  tripId,
  precise
}: RoutePlannerCurrentLocationGeoConfig) {
  const isMapSourceReady = useRef<boolean>(false);
  const currentLocationGeoPoint = useMemo(
    () =>
      point([longitude, latitude], {
        tripId,
        precise
      }),
    [longitude, latitude, tripId, precise]
  );

  useMapSourceEffect(() => {
    isMapSourceReady.current = true;
    updateRoutePlannerCurrentLocationSource(controllerId, currentLocationGeoPoint);
  }, controllerId);

  useEffect(() => {
    if (isMapSourceReady.current) {
      updateRoutePlannerCurrentLocationSource(controllerId, currentLocationGeoPoint);
    }
  }, [currentLocationGeoPoint, tripId]);

  return { currentLocationGeoPoint };
}
