import bearing from '@turf/bearing';
import { point, Position } from '@turf/helpers';

export const makeMapMarkerStartRotation = (positions: Position[]) => {
  const hasRoutePosition = positions && Boolean(positions.length > 1);
  const point1 = hasRoutePosition && point([positions[0][0], positions[0][1]]);
  const point2 = hasRoutePosition && point([positions[1][0], positions[1][1]]);
  const rotation = hasRoutePosition ? (bearing(point1, point2) + 180) % 360 : 0;

  return rotation;
};
