import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

export const DestinationIcon = React.memo((props: GlyphProps) => (
  <Glyph width={26} height={26} {...props}>
    <g clipPath="url(#clip0_2869_19048)">
      <path
        d="M22.1212 11.9211C22.1212 17.7586 13 23.961 13 23.961C13 23.961 3.87885 17.7586 3.87885 11.9211C3.87885 6.81323 8.16403 2.79993 13 2.79993C17.836 2.79993 22.1212 6.81323 22.1212 11.9211Z"
        fill="currentColor"
      />
      <path
        d="M13 15.2048C14.8135 15.2048 16.2836 13.7347 16.2836 11.9212C16.2836 10.1077 14.8135 8.63757 13 8.63757C11.1865 8.63757 9.71641 10.1077 9.71641 11.9212C9.71641 13.7347 11.1865 15.2048 13 15.2048Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2869_19048">
        <rect width="25.3806" height="25.3806" fill="white" transform="translate(0 0.380615)" />
      </clipPath>
    </defs>
  </Glyph>
));

DestinationIcon.displayName = 'DestinationIcon';
