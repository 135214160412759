import { reverseUrl } from 'lib/reverseUrl';
import { PublicURLModel } from 'models/PublicURLModel';
import Router from 'next/router';

let isChangingRoute = false;

export function updateTripUrl(tripId?: string) {
  // isChangingRoute is used to prevent "Abort fetching component for route" error
  if (!isChangingRoute && Router.pathname.startsWith(PublicURLModel.ROUTE)) {
    if (tripId) {
      if (Router?.query?.tripId !== tripId) {
        isChangingRoute = true;
        const url = reverseUrl(PublicURLModel.ROUTE_TRIP, { tripId });

        Router.push(url, url, { shallow: true }).finally(() => {
          isChangingRoute = false;
        });
      }
    } else if (Router?.query?.tripId) {
      isChangingRoute = true;
      Router.push(PublicURLModel.ROUTE, PublicURLModel.ROUTE, { shallow: true }).finally(() => {
        isChangingRoute = false;
      });
    }
  }
}
