import { fetchMultiplePlaceDetails, IPlace, PlaceTypeSlug } from '@truckmap/common';
import { PlaceExtraMetatypeDataDictionary, placeTypesWithExtraData } from 'models/PlaceDataModel';

async function fetchAndParseMultiplePlaceMetaTypes(
  place: IPlace
): Promise<PlaceExtraMetatypeDataDictionary> {
  const extraMetaSlugList = placeTypesWithExtraData.get(place.placeType.slug as PlaceTypeSlug);
  const filteredMetaTypes = place.placeDetails.filter((meta) =>
    extraMetaSlugList.includes(meta.placeMetaType.slug)
  );

  if (filteredMetaTypes.length) {
    const placeMetaIds = filteredMetaTypes.map<string>(({ placeMetaTypeId }) => placeMetaTypeId);
    const response = await fetchMultiplePlaceDetails(place.id, placeMetaIds);

    return response.reduce<PlaceExtraMetatypeDataDictionary>(
      (dictionary, currentResponse, index) => ({
        ...dictionary,
        [filteredMetaTypes[index].placeMetaType.slug]: currentResponse.data
      }),
      {}
    );
  }

  // avoid page crash in case none extra data is found
  return {};
}

export async function checkForExtraPlaceDetailData(
  place: IPlace
): Promise<PlaceExtraMetatypeDataDictionary> {
  return placeTypesWithExtraData.get(place.placeType.slug as PlaceTypeSlug)
    ? fetchAndParseMultiplePlaceMetaTypes(place)
    : Promise.resolve({});
}
