type Props = {
  disableTooltips?: boolean;
  disableTips?: boolean;
};
export const removeMapboxPopupStyle = ({ disableTooltips = true, disableTips = true }: Props) => {
  // This removes unnused mapbox style

  const tipsItems = document.querySelectorAll('.mapboxgl-popup-tip');
  tipsItems.forEach((item) => {
    item.setAttribute('style', 'display: block;');
  });

  if (disableTooltips) {
    const tooltipsItems = document.querySelectorAll('.mapboxgl-popup-content');
    tooltipsItems.forEach((item) => {
      item.classList.remove('mapboxgl-popup-content');
    });
  }

  if (disableTips) {
    tipsItems.forEach((item) => {
      item.classList.remove('mapboxgl-popup-tip');
    });
  }
};
