import { IPlaceDetail, PlaceMetaTypeSlug, PlaceTypeSlug } from '@truckmap/common';

export const placeTypesWithExtraData = new Map<PlaceTypeSlug, Array<PlaceMetaTypeSlug | string>>([
  // we don't need this at moment
  // [PlaceTypeSlug.WEIGH_STATION, [PlaceMetaTypeSlug.WEIGH_STATION_STATUS]]
]);

export type PlaceExtraMetatypeDataDictionary = {
  [meta: string]: IPlaceDetail[];
};
