import { useMapEffect, useMapGLContext } from 'hooks/useMap/useMapGL/MapGLContext';
import { useMapSourceEffectDispatcher } from 'hooks/useMap/useMapSource/useMapSourceEffect';
import { useRoutePlannerCurrentLocationSourceEffect } from 'hooks/useMap/useRoutePlannerMap/useRoutePlannerCurrentLocationSourceEffect';
import {
  locationInnerDotAnimation,
  locationInnerDotMapId
} from 'icons/routePlanner/locationInnerDotAnimation';
import {
  locationRingsAnimation,
  locationRingsMapId
} from 'icons/routePlanner/locationRingsAnimation';
import { formatMapBounds } from 'lib/map/formatMapBounds';
import { MapControllers } from 'models/MapControllers';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { geoIPSelector } from 'recoil/geoIP';
import { routePlannerAtom } from 'recoil/routePlanner';

import { AnyLayer } from 'mapbox-gl';
import { RoutePlannerMapLayers, useRoutePlannerMapLayers } from './useRoutePlannerMapLayers';
import { useRoutePlannerMapSource } from './useRoutePlannerMapSource';

type RoutePlannerMapProps = {
  longitude?: number;
  latitude?: number;
  controllerId: MapControllers;
};

export const MAPBOX_BELOW_LAYER = 'aerialway';

export const useRoutePlannerMap = ({ controllerId }: RoutePlannerMapProps) => {
  const mapboxContext = useMapGLContext();
  const layers = useRoutePlannerMapLayers();
  const mapInstance = mapboxContext.get(controllerId);
  const dispatchSourceEffect = useMapSourceEffectDispatcher(controllerId);
  const {
    longitude: currentLongitude,
    latitude: currentLatitude,
    precise
  } = useRecoilValue(geoIPSelector);
  const { tripId } = useRecoilValue(routePlannerAtom(controllerId));

  useEffect(() => {
    const bounds = mapInstance?.getBounds();
    if (bounds) {
      formatMapBounds(bounds);
    }
  }, [mapInstance]);

  const { currentLocationGeoPoint } = useRoutePlannerCurrentLocationSourceEffect({
    controllerId,
    tripId,
    precise,
    latitude: currentLatitude,
    longitude: currentLongitude
  });

  const { sources } = useRoutePlannerMapSource({
    currentLocationGeoPoint,
    latitude: currentLatitude,
    longitude: currentLongitude
  });

  useMapEffect(async (mapInstance) => {
    if (!mapInstance.hasImage(locationInnerDotMapId)) {
      mapInstance.addImage(locationInnerDotMapId, locationInnerDotAnimation(120, mapInstance), {
        pixelRatio: 2
      });
    }

    if (!mapInstance.hasImage(locationRingsMapId)) {
      mapInstance.addImage(locationRingsMapId, locationRingsAnimation(120, mapInstance), {
        pixelRatio: 2
      });
    }

    sources.forEach((source, sourceId) => {
      !mapInstance.getSource(sourceId) && mapInstance.addSource(sourceId, source);
    });

    dispatchSourceEffect();

    layers.forEach((layer) => {
      const belowLayerId =
        layer.id === RoutePlannerMapLayers.ROUTE ? MAPBOX_BELOW_LAYER : undefined;
      !mapInstance.getLayer(layer.id) && mapInstance.addLayer(layer as AnyLayer, belowLayerId);
    });
  }, controllerId);
};
