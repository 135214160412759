// This implements `StyleImageInterface`

// map items use blue
import { colors } from '@truckmap/common';
import { TruckMapGL } from 'hooks/useMap/useMapGL/MapGLContext';

export const locationRingsMapId = 'location-rings';

// to draw location rings on the map
export const locationRingsAnimation = (size: number, mapInstance: TruckMapGL) => ({
  width: size,
  height: size,
  data: new Uint8Array(size * size * 4),

  // When the layer is added to the map,
  // get the rendering context for the map canvas.
  onAdd: function () {
    const canvas = document.createElement('canvas');
    canvas.width = this.width;
    canvas.height = this.height;
    this.context = canvas.getContext('2d');
  },

  // Call once before every frame where the icon will be used.
  render: function () {
    const duration = 1500;
    const t = (performance.now() % duration) / duration;

    const radius = (size / 2) * 0.3;
    const outerRadius = (v) => (size / 2) * v * t + radius;
    const context = this.context;
    const opacity = {
      '0.6': 0.2,
      '0.4': 0.4,
      '0.2': 0.8,
      '0.0': 1
    };

    // Draw the outer circle.
    context.clearRect(0, 0, this.width, this.height);
    let velocity = 0.8;

    for (let index = 0; index < 3; index++) {
      velocity = velocity - 0.2;

      context.beginPath();
      context.arc(this.width / 2, this.height / 2, outerRadius(velocity), 0, Math.PI * 2);
      context.lineWidth = 2 + 4 * (1 - t);
      context.strokeStyle = `rgba(38, 110, 187, ${opacity[velocity.toFixed(1)]})`;
      context.stroke();
    }

    // Draw the inner circle.
    context.beginPath();
    context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
    context.fillStyle = 'rgba(255, 255, 255, 1)';
    context.strokeStyle = colors.blue800;
    context.lineWidth = 10;
    context.fill();
    context.stroke();

    // Update this image's data with data from the canvas.
    this.data = context.getImageData(0, 0, this.width, this.height).data;

    // Continuously repaint the map, resulting
    // in the smooth animation of the dot.
    mapInstance.triggerRepaint();

    // Return `true` to let the map know that the image was updated.
    return true;
  }
});
