export function reverseUrl<P extends Record<string, string>>(url: string, params: P) {
  const urlParts = url.split('/');
  const parsedUrlParams = urlParts.map((part: string) => {
    if (!part.startsWith(':')) {
      return part;
    }

    const value = params[part.slice(1)];

    if (!value) {
      const errorMessage = `Missing url param "${part}" in "${url}"`;

      if (!process.env.JEST_WORKER_ID) {
        console.error(errorMessage);
      }

      throw errorMessage;
    }

    return value;
  });

  return parsedUrlParams.join('/');
}
