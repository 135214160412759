// This implements `StyleImageInterface`
import { TruckMapGL } from 'hooks/useMap/useMapGL/MapGLContext';

const maxGrowWidth = 10;
const minGrowWidth = 2;
const growStep = 0.05;

export const locationInnerDotMapId = 'location-inner-dot';

// to draw a location dot icon on the map.
export const locationInnerDotAnimation = (size: number, mapInstance: TruckMapGL) => ({
  width: size,
  height: size,
  data: new Uint8Array(size * size * 4),

  // When the layer is added to the map,
  // get the rendering context for the map canvas.
  onAdd: function () {
    const canvas = document.createElement('canvas');
    canvas.width = this.width;
    canvas.height = this.height;
    this.context = canvas.getContext('2d');
    this.currentLineWidth = maxGrowWidth + growStep;
    this.growOut = true;
  },

  // Call once before every frame where the icon will be used.
  render: function () {
    if (this.growOut) {
      this.currentLineWidth = this.currentLineWidth - growStep;
      if (this.currentLineWidth < minGrowWidth) {
        this.growOut = false;
      }
    } else {
      this.currentLineWidth = this.currentLineWidth + growStep;
      if (this.currentLineWidth > maxGrowWidth) {
        this.growOut = true;
      }
    }

    const radius = (size / 2) * 0.3;
    const context = this.context;

    // Draw the inner circle.
    context.beginPath();
    context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
    context.fillStyle = 'rgba(38, 110, 187, 1)';
    context.strokeStyle = 'white';
    context.lineWidth = this.currentLineWidth;
    context.fill();
    context.stroke();

    // Update this image's data with data from the canvas.
    this.data = context.getImageData(0, 0, this.width, this.height).data;

    // Continuously repaint the map, resulting
    // in the smooth animation of the dot.
    mapInstance.triggerRepaint();

    // Return `true` to let the map know that the image was updated.
    return true;
  }
});
