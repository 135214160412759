module.exports = {
  colors: {
    // blue updates
    blue900: '#0F4377',
    blue850: '#4C94D0', // primary hover
    blue800: '#2F6FAF', // primary
    blue600: '#3070B2',
    blue400: '#8AA7BE',
    blue200: '#AAC6E4',
    // end blue updates
    blue100: '#C8DBF0',
    blue050: '#E4EEF9',
    blue025: '#E9F1FB',
    blue010: '#F2F7FD',
    blue005: '#F6FAFF',
    gray800: '#112337',
    gray600: '#2D4054',
    gray400: '#5E6D7D',
    gray200: '#6A7683',
    gray100: '#BAC0C7',
    gray050: '#D1D5D9',
    gray040: '#E4E5E7',
    gray040: '#ECEDEE',
    gray030: '#F2F4F6',
    gray020: '#F5F6F7',
    gray010: '#FCFCFD',
    paragraph800: '#4F5E70',
    paragraph700: '#7E8A98',
    paragraph600: '#A3AFBC',
    paragraph100: '#C6E1FF',
    black: '#000A10',
    white: '#FFFFFF',
    success700: '#125B3D',
    success600: '#34785C',
    success550: '#28855E',
    success500: '#2A9367',
    success400: '#59A786',
    success075: '#EEF9F4',
    success050: '#F1FBF7',
    error700: '#8C0505',
    error600: '#BB0808',
    error500: '#DC2E2E',
    error400: '#EB6565',
    error075: '#FBF1F1',
    error050: '#FCF3F3',
    info700: '#1E5896', // blue900
    info600: '#2665AA', // blue850
    info500: '#558FCE', // blue600
    info400: '#84ADD9', // blue400
    info050: '#F0F7FF', // brightBlue075
    info075: '#EEF6FF', // brightBlue050
    magenta700: '#73054E',
    magenta600: '#A70671',
    magenta500: '#D71494',
    magenta200: '#FFC9EC',
    magenta050: '#FFF5FC',
    pink700: '#950539',
    pink600: '#CA0D51',
    pink500: '#FD4E8D',
    pink200: '#FFD4E4',
    pink050: '#FFF4F8',
    orange700: '#AE5400',
    orange600: '#C87223',
    orange500: '#EC8322',
    orange200: '#FFD8B5',
    orange050: '#FFF7F0',
    yellow700: '#EEC73D',
    yellow600: '#F5D669',
    yellow500: '#FFEAA1',
    yellow200: '#FFF4CD',
    yellow050: '#FFFDF5',
    green700: '#056103',
    green600: '#259523',
    green500: '#3ABF37',
    green200: '#BDFBBC',
    green050: '#F5FFF4',
    turquoise700: '#047264',
    turquoise600: '#02A490',
    turquoise500: '#04D3BB',
    turquoise200: '#AFECE4',
    turquoise050: '#F1FFFD',
    brightBlue700: '#035AB9',
    brightBlue600: '#0F77E9',
    brightBlue500: '#3796FE',
    brightBlue200: '#BADBFF',
    brightBlue075: '#EEF6FF',
    brightBlue050: '#F0F7FF',
    indigo700: '#20248B',
    indigo600: '#373CC5',
    indigo500: '#4C53F1',
    indigo200: '#CDCFFF',
    indigo050: '#F2F2FF'
  }
};
