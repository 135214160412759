import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

const RoutePlannerOriginIcon = React.memo((props: GlyphProps) => (
  <Glyph width={32} height={32} {...props}>
    <g filter="url(#filter0_drpgi)">
      <circle r="10" transform="matrix(1 0 0 -1 16 14)" fill="white" />
      <circle r="11" transform="matrix(1 0 0 -1 16 14)" stroke="#266EBC" strokeWidth="2" />
    </g>
    <path
      d="M15.1056 18.0445L12.1388 12.1111C11.7324 11.2982 12.5334 10.4084 13.3844 10.7275L15.6489 11.5767C15.8753 11.6616 16.1247 11.6616 16.3511 11.5767L18.6156 10.7275C19.4666 10.4084 20.2676 11.2982 19.8612 12.1111L16.8944 18.0445C16.5259 18.7816 15.4741 18.7816 15.1056 18.0445Z"
      fill="#266EBC"
    />
    <defs>
      <filter
        id="filter0_drpgi"
        x="0"
        y="0"
        width="32"
        height="32"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0" />
        <feBlend mode="multiply" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </Glyph>
));

RoutePlannerOriginIcon.displayName = 'RoutePlanner/RoutePlannerOriginIcon';

export { RoutePlannerOriginIcon };
