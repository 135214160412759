import { getGeolocationFromCookie, LatLonWithPreciseInfo } from 'lib/map/getGeolocation';
import { selector } from 'recoil';

import { geoIPAtom } from './geoIPAtom';

export const geoIPSelector = selector<LatLonWithPreciseInfo>({
  key: 'geoIPSelector',
  get: ({ get }) => {
    const currentLocation = get(geoIPAtom);

    if (currentLocation) {
      return currentLocation;
    }

    return getGeolocationFromCookie();
  }
});
