import { ContentfulQuery, ContentfulResponse } from '@truckmap/common';
import { staticClient } from 'clients/static';
import { IPage } from 'types/generated/contentful';

export type ContentfulCacheConfig = {
  queryName?: 'publicPages' | 'privatePages' | 'placeDetails' | 'truckOptions';
  variables?: object;
  legacyRelativePath?: string;
};

export type ContentfulCachePayload = {
  contentfulQueryResponse?: ContentfulResponse<ContentfulQuery>;
  legacyLayoutResponse?: IPage[];
};

export async function queryContentfulCache({
  variables,
  ...config
}: ContentfulCacheConfig): Promise<ContentfulCachePayload> {
  const response = await staticClient.get<ContentfulCachePayload>('/api/contentful/cache', {
    params: {
      ...config,
      variables: variables ? JSON.stringify(variables) : null
    }
  });

  return response.data;
}
